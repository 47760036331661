


/**
 * SELECT2
 * custom select elements via Select2 library
 */
$(document).ready(function() {
    $('.js-select2').select2();
});


/**
 * ENHANCED DATA TABLES
 * utilises the Data Tables library
 * https://datatables.net/
 */
$(document).ready(function() {

    //HH:mma \o\n DD/MM/YYYY

    $.fn.dataTable.moment( 'HH:mma DD/MM/YYYY' );

    var dataTable = $('.js-table-enhanced').DataTable({
        "sDom": '<"top"i>rt<"bottom"lp><"clear">',
        stateSave: false,
        "drawCallback": function ( settings ) {
            /*show pager if only necessary*/
             //console.log(this.fnSettings().fnRecordsDisplay(), this.fnSettings()._iDisplayLength);
            if (Math.ceil((this.fnSettings().fnRecordsDisplay()) / this.fnSettings()._iDisplayLength) > 1) {
                $(this).parent().find('.dataTables_paginate').css("display", "block");
            } else {
                $(this).parent().find('.dataTables_paginate').css("display", "none");
            }

        }
    });


    var dataTableProd = $('.js-table-enhanced-products').DataTable({
        "sDom": '<"top"i>rt<"bottom"lp><"clear">',
        stateSave: false,
        "ajax": {
            "url": "/product/ajaxTable",
            "data": function ( d ) {
                return $.extend( {}, d, {
                    "categories": searchFilters
                } );
            }
        },
        "deferRender": true,
        "serverSide": true,
        "drawCallback": function ( settings ) {

            /*show pager if only necessary*/
            //console.log(this.fnSettings().fnRecordsDisplay(), this.fnSettings()._iDisplayLength);
            if (Math.ceil((this.fnSettings().fnRecordsDisplay()) / this.fnSettings()._iDisplayLength) > 1) {
                $(this).parent().find('.dataTables_paginate').css("display", "block");
            } else {
                $(this).parent().find('.dataTables_paginate').css("display", "none");
            }

        }
    });

    $('.js-datatable-search').keyup(function(){
        dataTable.search( $(this).val() ).draw();
        dataTableProd.search( $(this).val() ).draw();
    });

});


/**
 * SELECT ALL ROWS IN A TABLE
 * allow single checkbox to select all tables rows
 */
$(document).ready(function() {
    var checkboxes;
        
    $('.js-tr-select-all').on('change', function() {
        var triggerStatus = $(this).prop("checked");  

        // Has to be "live" as Datatable adds/removes rows on the fly
        checkboxes = $(this).parents('.js-table-enhanced').find('.js-tr-select');

        checkboxes.each(function() {
            $(this).prop('checked', triggerStatus);
        });
    });
});


/**
 * ACTIVE TABS SELECTION
 * by default boostrap doesn't persist the active tab from the 
 * url hash. We enable this functionality here.
 */
(function() {

    var url = document.location.toString();
    
    if (url.match('#')) {
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
    } 

    // Change hash for page-reload
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
        window.scrollTo(0, 0);
    })
}());
// Javascript to enable link to tab


/**
 * WYSIWYG TEXT AREA
 */
$(document).ready(function() {
    $('.js-enhanced-textarea').wysihtml5({
        toolbar: {
            "font-styles": true, //Font styling, e.g. h1, h2, etc. Default true
            "emphasis": true, //Italics, bold, etc. Default true
            "lists": true, //(Un)ordered lists, e.g. Bullets, Numbers. Default true
            "html": true, //Button which allows you to edit the generated HTML. Default false
            "link": true, //Button to insert a link. Default true
            "image": false, //Button to insert an image. Default true,
            "color": false, //Button to change color of font  
            "blockquote": false //Blockquote 
        }
    });
});



/**
 * FORM REQUIRE CONFIRMATION
 * requires confirmation of modal before form submits
 */
$('.js-form-require-confirmation').on('submit', function(e){
    var $form = $(this);
    
    e.preventDefault();
    
    var $modal = $('.js-confirmation-modal').modal();
    
    $modal.one('click', '.js-confirmation-modal-action', function() {
        debugger;
        // Remove all Event handlers
        $form.off('submit');        
        $modal.off('click');
        $('.link-confirmation-modal').off('show.bs.modal');

        // Confirmation "Yes" then submit form
        if ( $(this).data('confirmation') === "yes" ) {
            $form.submit();
        }
    });
});

/**
 * Link REQUIRE CONFIRMATION
 * requires confirmation of modal before link follows
 */
$('.link-confirmation-modal').on('show.bs.modal', function (e) {

    $(this).one('click', '.js-confirmation-modal-action', function() {

        // Remove all Event handlers
        $(this).off('click');

        // Confirmation "Yes" then redirect
        if ( $(this).data('confirmation') === "yes" ) {
            document.location = $(e.relatedTarget).data('url');
        }
    });
});


$('#BulkStoreProducts').on('submit', function(e){
    var $form = $(this);
    e.preventDefault();

    var checked = [];

    $('input:checkbox.productSelect').each(function () {
        if(this.checked) {
            checked.push($(this).val());
        }
    });

    if($('#ProductBulkAction').val() == 'remove') {
        $('#BulkUnassignProducts').val(checked);
    }
    $form.off('submit');
    $('#BulkStoreProducts').submit();
});

/**
 * Bulk update orders
 */
$('#BulkOrderForm').on('submit', function(e){
    var $form = $(this);
    e.preventDefault();

    var checked = [];

    $('input:checkbox[name="orderSelect[]"]').each(function () {
        console.log(this);
        if(this.checked) {
            checked.push($(this).val());
        }
    });

    $('#BulkOrders').val(checked);

    $form.off('submit');
    $('#BulkOrderForm').submit();
});

/**
 * Bulk update orders
 */
$('#UserBulkForm').on('submit', function(e){
    var $form = $(this);
    e.preventDefault();

    var checked = [];

    $('input:checkbox[name="userSelect[]"]').each(function () {
        console.log(this);
        if(this.checked) {
            checked.push($(this).val());
        }
    });

    $('#BulkUsers').val(checked);

    $form.off('submit');
    $('#UserBulkForm').submit();
});


userTypeChooseCompany();
$('#form_user_type').on('change', function(){
    userTypeChooseCompany();
});

function userTypeChooseCompany() {
    var selected = $('#form_user_type option:selected').text();

    // Hide if we choose admin
    if(selected == 'Administrator') {
        $('#client_id_form').hide();
    } else {
        $('#client_id_form').show();
    }
}


/**
 * Note edits on product page
 */
$('.orderNoteEdit').on('click', function(e){
    e.preventDefault();
    $('#note-form').collapse('show');

    $('#note_id').val($(this).data('noteid'));
    $('#note_title').val($(this).data('notetitle'));
    $('#note_message').val($(this).data('notebody'));
});

/**
 * New note on product page
 */
$('.addNote').on('click', function(e){
    e.preventDefault();
    if($('#note-form').hasClass('in') && $('#note_id').val() == '') {
        $('#note-form').collapse('hide');
    } else {
        $('#note-form').collapse('show');
    }

    $('#note_id').val('');
    $('#note_title').val('');
    $('#note_message').val('');
});


/**
 * Delivery edits on product page
 */
$('.orderDeliveryEdit').on('click', function(e){
    e.preventDefault();
    $('#delivery-form').collapse('show');

    $('#delivery_id').val($(this).data('deliveryid'));
    $('#delivery_line_item').val($(this).data('deliverylineitem'));
    $('#delivery_dispatched').val($(this).data('deliverydispatched'));
    $('#delivery_shipped').val($(this).data('deliverydateshipped'));
    $('#delivery_tracking').val($(this).data('deliverytracking'));
    $('#delivery_courier').val($(this).data('deliverycourier'));
});

/**
 * New delivery on product page
 */
$('.addDelivery').on('click', function(e){
    e.preventDefault();
    if($('#delivery-form').hasClass('in') && $('#delivery_id').val() == '') {
        $('#delivery-form').collapse('hide');
    } else {
        $('#delivery-form').collapse('show');
    }

    $('#delivery_id').val('');
    $('#delivery_line_item').val('');
    $('#delivery_dispatched').val('');
    $('#delivery_shipped').val('');
    $('#delivery_tracking').val('');
    $('#delivery_courier').val('');
});

// Set the default for datepicker to be GB standard
$.fn.datepicker.defaults.format = 'dd/mm/yyyy';

/**
 * Reset filters
 */
$('.resetFilters').on('click', function(e) {
    e.preventDefault();
    var form = document.getElementById('SearchFilterForm');
    // standard reset
    form.reset();
    // Select2 reset
    $('#SearchFilterForm .js-select2').select2('val', '');
    // Datepicker reset
    $('#SearchFilterForm [data-provide="datepicker"]').val('').datepicker('update');
    // finally submit the form
    form.submit();
});

/**
 * Enable bootstrap tooltips
 */
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});

/**
 * Accept orders via AJAX
 */
$('.approveOrder').on('click', function(e) {
    e.preventDefault();
    var OrderId = $(this).data('orderid');
    var $modal = $(document.getElementById('ajax-confirmation-modal'));
    $modal.find('.modal-body').html('Are you sure you want to approve this order?');
    $modal.modal();

    $modal.one('click', '.js-confirmation-modal-action', function() {

        // Remove all Event handlers
        $modal.off('click');
        $('.link-confirmation-modal').off('show.bs.modal');

        // Confirmation "Yes" then submit form
        if ( $(this).data('confirmation') === "yes" ) {

            var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');

            $.ajax({
                url: '/order/' + OrderId + '/status',
                type: 'POST',
                data: {
                    _token: CSRF_TOKEN,
                    isAjax: true,
                    status: 'Order Approved'
                },
                dataType: 'JSON',
                success: function (data) {
                    if(data.success == true) {
                        $('#order'+OrderId).html('<span class="label label-success">Order Approved</span>')
                    }
                }
            });

        }
    });
});

/**
 * Cancel orders via AJAX
 */
$('.declineOrder').on('click', function(e) {
    e.preventDefault();
    var OrderId = $(this).data('orderid');
    var $modal = $(document.getElementById('ajax-confirmation-modal'));
    $modal.find('.modal-body').html('Are you sure you want to decline this order?');
    $modal.modal();

    $modal.one('click', '.js-confirmation-modal-action', function() {

        // Remove all Event handlers
        $modal.off('click');
        $('.link-confirmation-modal').off('show.bs.modal');

        // Confirmation "Yes" then submit form
        if ( $(this).data('confirmation') === "yes" ) {

            var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');

            $.ajax({
                url: '/order/' + OrderId + '/status',
                type: 'POST',
                data: {
                    _token: CSRF_TOKEN,
                    isAjax: true,
                    status: 'Cancelled'
                },
                dataType: 'JSON',
                success: function (data) {
                    if(data.success == true) {
                        $('#order'+OrderId).html('<span class="label label-danger">Order Cancelled</span>')
                    }
                }
            });

        }
    });
});


$('#BulkCompanyForm').on('submit', function(e){
    var $form = $(this);
    e.preventDefault();

    var checked = [];

    $('input:checkbox.companySelect').each(function () {
        if(this.checked) {
            checked.push($(this).val());
        }
    });

    $('#bulkCompanies').val(checked);

    $form.off('submit');
    $('#BulkStoreProducts').submit();
});

/**
 * Make sure the restricted sales window stays open on form validation failure
 */
(function(){

    if($('input[name="restrictedSalesWindow"]:checked').val() == 1) {
        $('#sales-window').addClass('in');
        $('#sales-window').attr('aria-expanded', true);
    }


    $('input[name="restrictedSalesWindow"]').on('change', function(e) {
        e.preventDefault();
        e.stopPropagation();

        if($('input[name="restrictedSalesWindow"]:checked').val() == 1) {
            $('#sales-window').addClass('in');
            $('#sales-window').attr('aria-expanded', true);
        } else {
            $('#sales-window').removeClass('in');
            $('#sales-window').attr('aria-expanded', false);
        }
    });
})();

/**
 * Signature handling
 */
(function () {
    var $sigdiv = $("#signature");

    if($sigdiv.length > 0) {
        $sigdiv.jSignature();

        $sigdiv.bind('change', function (e) {
            $('#signatureField').val($sigdiv.jSignature('getData', 'svgbase64'));
            $('#signatureFieldBase30').val($sigdiv.jSignature('getData', 'base30'));
        });

        $('#signatureField').val('');
        $sigdiv.jSignature("setData", "data:" + $('#signatureFieldBase30').val());
        $('#signatureField').change();

        $('.resetSig').on('click', function (e) {
            e.preventDefault();

            $sigdiv.jSignature('clear');
            $('#signatureField').val('');
        });
    }
})();

/**
 * Company creation slug
 */
(function(){
    $('#js-company_name, #form_company_url').on('blur', function(e){

        $.ajax({
            url: '/ajax/slug/company',
            type: 'POST',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                name: $(this).val()
            },
            dataType: 'JSON',
            success: function (data) {
                if(data.success == true) {
                    $('#form_company_url').val(data.slug);
                }
            }
        });


    });
})();

/**
 * Password strength indicator
 *
 * Uses zxcvbn.js
 */
(function(){

    $('.passStrength').on('keydown', function(){

        var strength = zxcvbn($(this).val());
        var bar = $('.passStrengthBar');
        var barClass = '';
        var barWidth = 0;
        var barText = '';

        switch(strength.score) {
            case 0:
                barClass = 'progress-bar-danger';
                barWidth = 20;
                barText = 'Weak';
                break;

            case 1:
                barClass = 'progress-bar-warning';
                barWidth = 40;
                barText = 'Weak';
                break;

            case 2:
                barClass = 'progress-bar-warning';
                barWidth = 60;
                barText = 'Weak';
                break;

            case 3:
                barClass = 'progress-bar-success';
                barWidth = 80;
                barText = 'Good';
                break;

            case 4:
                barClass = 'progress-bar-success';
                barWidth = 100;
                barText = 'Excellent';
                break;
        }

        console.log(strength);

        bar.attr('aria-valuenow', barWidth);
        bar.css('width', barWidth + '%');
        bar.removeClass('progress-bar-danger progress-bar-warning progress-bar-success');
        bar.addClass(barClass);
        if(strength.feedback.suggestions.length > 0) {
            $('#password_field_helpblock').html(strength.feedback.suggestions[0]);
        } else {
            $('#password_field_helpblock').html('');
        }
        $('.passStrengthBarText').html(strength.feedback.warning);

    });

})();

(function(){

    $('.scheme-type').on('change', function(){
        var value = $(this).val();
        setSchemeType(value);
    });

    setSchemeType($('.scheme-type').val());

    function setSchemeType(value){
        if(value == 2) {
            $('.contract36').attr('disabled', 'disabled').parent().hide();
            $('.contract24').attr('disabled', 'disabled').parent().hide();
            $('.contract12').prop('checked', true);
        } else {
            $('.contract36').removeAttr('disabled').parent().show();
            $('.contract24').removeAttr('disabled').parent().show();
        }
    }

})();

/**
 * New note on company page
 */
$('.js-addcompanynote').on('click', function(e){
    e.preventDefault();

    console.log($(this));

    if($('#note-form').hasClass('in')) {
        $('#note-form').collapse('hide');
        $('#note_id').val('');
    } else {
        $('#note_id').val($(this).data('note'));
        $('#note-form').collapse('show');
    }

    $('#note_message').val('');
});

(function(){
    if(typeof PDFURL !== 'undefined' && PDFURL.length > 0) {
        PDFObject.embed(PDFURL, "#PDFContainer");
    }
})();